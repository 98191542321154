import { styled } from '@mui/material';
import React from 'react';
import logo from './logo.png';

const HeaderContainer = styled('header')(({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    padding: theme.spacing(1, 3),
    backgroundColor: '#333333',
    '& .logo': {
      objectFit: 'cover'
    },
    '& .nav': {
      display: 'grid',
      gridTemplateColumns: 'max-content max-content',
      gap: theme.spacing(3),
      alignContent: 'center'
    },
    '& .navLink': {
      color: '#ffffff',
      fontSize: 24,
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontFamily: `sans-serif`,
      fontWeight: 400,
      fontStyle: 'normal',
    }

  }
));

export const Header = () => {
  return (
    <HeaderContainer>
      <img src={logo} alt='tojaplussize.pl logo' className='logo'/>
      <nav className='nav'>
        <a className='navLink' href='#onas'>O nas</a>
        <a className='navLink' href='#kontakt'>Kontakt</a>
      </nav>
    </HeaderContainer>
  );
}
