import { styled } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import img75 from './hero-1440w.jpg';
import img100 from './hero-1920w.jpg';
import img50 from './hero-960w.jpg';

const HeroSection = styled('section')({
  '& .hero': {
    objectFit: 'cover',
    objectPosition: 'center top',
    minHeight: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    minWidth: '100%',
  }
});

export const Hero = () => {
  return (
    <HeroSection id='hero'>
      <Helmet>
        <link
          rel="preload"
          href={img50}
          as="image"
          media="(max-width: 1024px)"/>
        <link
          rel="preload"
          href={img75}
          as="image"
          media="(min-width: 1024.1) and (max-width:1600)"/>
        <link
          rel="preload"
          href={img100}
          as="image"
          media="(min-width: 1600.1px)"/>
      </Helmet>
      <picture>
        <source media="(max-width: 1024px)" srcSet={img50}/>
        <source media="(max-width: 1600px)" srcSet={img75}/>
        <img
          className='hero'
          src={img100} alt=""/>
      </picture>
    </HeroSection>
  );
};
