import { styled } from '@mui/material';
import React from 'react';
import { About } from './components/about/About';
import { Contact } from './components/contact/Contact';
import { Header } from './components/header/Header';
import { Hero } from './components/hero/Hero';

export const AppContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: 'min-content 100vh max-content 100vh'
});

export const App = () => {
  return (
    <AppContainer>
      <Header/>
      <Hero/>
      <About/>
      <Contact/>
    </AppContainer>
  );
};

