import { createTheme, ThemeProvider } from '@mui/material';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './App';
import './index.css';

const theme = createTheme();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </HelmetProvider>
  </StrictMode>
);
