import { styled, Typography } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import { Title } from '../Title';
import { Poi } from './Poi';

const MAPS = {
  key: 'AIzaSyCbpYmM-9M415_vWpBov97i6It3ZV5YPh0',
  cords: {
    lat: 50.06934,
    lng: 19.9375135
  },
  zoom: 20
};

const SectionContainer = styled('section')(({theme}) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
}));

export const Contact = () => {
  return (
    <SectionContainer id='kontakt'>
      <Title>Kontakt</Title>
      <GoogleMapReact
        bootstrapURLKeys={{key: MAPS.key}}
        defaultCenter={MAPS.cords}
        defaultZoom={MAPS.zoom}
      >
        <Poi
          lat={MAPS.cords.lat}
          lng={MAPS.cords.lng}
          title="TO JA">
          <Typography variant='body1'>
            Słowiańska 6, 31-146 Kraków
          </Typography>
        </Poi>
      </GoogleMapReact>
    </SectionContainer>
  );
};
