import { styled, Typography, TypographyProps } from '@mui/material';
import React from 'react';

const UnstyledTitle = (props: React.PropsWithChildren<Omit<TypographyProps, 'variant'>>) => {
  const {children, ...otherProps} = props;

  return <Typography variant='h2' {...otherProps}>{children}</Typography>;
}

export const Title = styled(UnstyledTitle)(({theme}) => ({
  maxWidth: 1000,
  margin: theme.spacing(0, 'auto', 3, 'auto'),
}));
