import { LocationOn } from '@mui/icons-material';
import { Card, CardContent, CardHeader, CardMedia, GlobalStyles, Tooltip } from '@mui/material';
import React from 'react';
import logo from './img.jpg';

export interface PoiProps {
  title: string,
  lat: number,
  lng: number
}

export const Poi = (props: React.PropsWithChildren<PoiProps>) => {
  const {title, children} = props;

  return (
    <>
      <GlobalStyles styles={{
        '.poi-tooltip .MuiTooltip-tooltip': {
          backgroundColor: 'transparent !important',
        }
      }}/>
      <Tooltip
        PopperProps={{
          className: 'poi-tooltip'
        }}
        placement={'top'}
        open={true}
        title={
          <Card variant={'elevation'} style={{minWidth: 300}}>
            <CardHeader title={title}/>
            <CardMedia component={'img'} image={logo} style={{backgroundColor: '#333333'}}
                       alt="Logo TO JA Plus Size"/>
            <CardContent>
              {children}
            </CardContent>
          </Card>
        }
      >
        <LocationOn style={{color: '#e20177', fontSize: 40}}/>
      </Tooltip>
    </>
  );
};
