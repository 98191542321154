import { styled, Typography } from '@mui/material';
import React from 'react';
import { Title } from '../Title';

const paragraphs = `
    TO JA! Piękno! Nie znam rozmiaru i uważam, że ubrania plus size nie muszą wyglądać jak worek pokutny! Wierzę, że każda kobieta powinna czuć się dobrze i atrakcyjnie w swoich ubraniach. Duży rozmiar nie musi być nudny!
    Zapraszam do mojego sklepu po sukienkę idealną na wesele koleżanki, bluzkę w której będziesz czuć się wygodnie w pracy czy płaszcz otulający idealnie Twoją figurę.
    `
  .split('\n')
  .map(paragraph => paragraph.trim())
  .filter(Boolean);

const SectionContainer = styled('section')(({theme}) => ({
  '.content': {
    maxWidth: 1000,
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(2, 0)
  }
}));

export const About = () => {

  return (
    <SectionContainer id='onas'>
      <div className='content'>
        <Title>O nas</Title>
        {paragraphs.map((paragraph, key) => (
          <Typography key={`paragraph-${key}`} paragraph>{paragraph}</Typography>
        ))}
      </div>
    </SectionContainer>
  );
};
